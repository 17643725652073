import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading as HeadingTitle,
  Subheading,
} from "components/misc/Headings.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
// import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";
// import
// //  HeaderBase,
//  {
//   NavLinks,
//   NavLink,
//   // PrimaryLink,
// } from "components/headers/light.js";
import Header from "components/headers/light.js";
import Pen from "../../images/newImg/pen.jpg";
import place from "../../images/newImg/place.png";
import thought from "../../images/newImg/thought.jpg";
import globe from "../../images/newImg/globe.jpg";
import hat from "../../images/newImg/hat.png";
import medal from "../../images/newImg/medal.png";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;
// const Header = tw(HeaderBase)`max-w-none`;
const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`,
]);

const Details = tw.div`p-6  flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
// const MetaContainer = tw.div`flex items-center`;
// const Meta = styled.div`
//   ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
//   svg {
//     ${tw`w-4 h-4 mr-1`}
//   }
// `;

// const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
// const Link = styled(PrimaryButtonBase).attrs({ as: "a" })`
//   ${tw`inline-block mt-4 text-sm font-semibold`}
// `;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({
  subheading = "",
  heading = (
    <>
      SigSAIL <span tw="text-primary-500">TMS</span>
    </>
  ),
  description = "",
}) => {
  const blogPosts = [
    {
      imageSrc: medal,
      author: "Adam Wathan",
      category: "SEO",
      title: "Optimizing your website for your main keyword",
      description:
        "TMS custom test builder gives you endless ways evaluate professionals, translators, and interpreters using speaking, listening, reading and writing activities. Administer your certification exam to just a few test takers or hundreds of individuals -- uniformly and across numerous locations.",
      url: "https://reddit.com",
    },
    {
      imageSrc: hat,
      author: "Owais Khan",
      category: "Advertising",
      title: "Creating The perfect advertisement campaign",
      description:
        "TMS is a flexible assessment solution that can be applied to any learning situation. Some of the world's most prestigious institutions use TMS for study abroad, testing center management, world language oral proficiency, and pre- and post-graduate placement exams including LCTL, SPEAK, and ESL.",
      url: "https://timerse.com",
    },
    {
      imageSrc: globe,
      author: "Steve Schoger",
      category: "Social Media",
      title: "Efficient management of your social media assets",
      description:
        "Reduce overall expenses by streamlining the authoring, scheduling, administration, and scoring of pre-employment screening, employee evaluations, compliance testing, and continuing education. Create assessment items to replicate scenarios and competencies specific to your profession, industry or workplace.",
      url: "https://timerse.com",
    },
    {
      imageSrc: thought,
      author: "Steve Schoger",
      category: "Social Media",
      title: "Efficient management of your social media assets",
      description:
        "With TMS you can create uniform testing to assess professional knowledge, communication skills and cultural competencies against any standards or requirements. Include media to replicate real life scenarios. Gather and maintain performance data by individual, program, department or agency.",
      url: "https://timerse.com",
    },
    {
      imageSrc: place,
      author: "Steve Schoger",
      category: "Social Media",
      title: "Efficient management of your social media assets",
      description:
        "We are proud of the way the SigSAIL TMS has helped organizations around the world reach their assessment and business goals. Our satisfied users have kindly shared the specific ways they have applied the TMS to their testing situation. Or, continue below to read some user testimonials.",
      url: "https://timerse.com",
    },
    {
      imageSrc:Pen,
      author: "Steve Schoger",
      category: "Social Media",
      title: "Efficient management of your social media assets",
      description:
        "Don't be constrained by a publisher's predetermined computerized testing solution. Administrators and teachers can build and deliver high-stakes finals, SOPI-type assessments, standardized testing, media-rich homework assignments, classroom quizzes, chapter tests, practice activities, and more",
      url: "https://timerse.com",
    },
  ];
  // const navLinks = [
  //   <NavLinks key={1}>
  //     <NavLink href="/">Home</NavLink>
  //     <NavLink href="/projects">Projects</NavLink>
  //     <NavLink href="/sigsail-tms">SigSAIL TMS</NavLink>
  //     <NavLink href="/sigsail-tms">IIITD-IC Incubated</NavLink>
  //     <NavLink href="#">Clients</NavLink>
  //     <NavLink href="#">Collaborations</NavLink>
  //     <NavLink href="#">Contact us</NavLink>
  //   </NavLinks>,
  //   <NavLinks key={2}>
  //     {/* <PrimaryLink href="/#">
  //       Hire Us
  //     </PrimaryLink> */}
  //   </NavLinks>,
  // ];
  return (
    <Container>
      <Header />
      <Content>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Details>
                  {/* <MetaContainer>
                    <Meta>
                      <UserIcon />
                      <div>{post.author}</div>
                    </Meta>
                    <Meta>
                      <TagIcon />
                      <div>{post.category}</div>
                    </Meta>
                  </MetaContainer> */}
                  {/* <Title>{post.title}</Title> */}
                  <Description>{post.description}</Description>
                  {/* <Link href={post.url}>Read Post</Link> */}
                </Details>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
