import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
// import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      {/* <ContactUsForm /> */}
      <ContactDetails
        cards={[
          {
            title: "Office Address",
            description: (
              <>
                <Address>
                  <AddressLine>
                    Signal Sciences and Artificial Intelligence Laboratories
                    (SigSAIL) Pvt Ltd.
                  </AddressLine>
                  <AddressLine>
                    IIITD Innovation and Incubation Center
                  </AddressLine>
                  <AddressLine>Level 5, Seminar Block</AddressLine>
                  <AddressLine>
                    IIIT Delhi, Okhla Industrial Estate Phase 3
                  </AddressLine>{" "}
                  <AddressLine>Near Govindpuri Metro Station</AddressLine>{" "}
                  <AddressLine> Delhi, India Pin Code: 110020</AddressLine>
                </Address>
                {/* <Email>contact@treact.com</Email>
                <Phone>+1 (203) 991-6988</Phone> */}
              </>
            ),
          },
          {
            title: "Registered Address",
            description: (
              <>
                <Address>
                  <AddressLine>
                    Signal Sciences and Artificial Intelligence Laboratories
                    (SigSAIL) Pvt Ltd.{" "}
                  </AddressLine>
                  <AddressLine>
                    Shri Ganesh Plaza, Kailash Nagar, Narnaul - Rewari Road,
                  </AddressLine>
                  <AddressLine>Narnaul, Haryana,</AddressLine>
                  <AddressLine>India Pin Code: 123001</AddressLine>
                </Address>
                {/* <Email>contact@treact.com</Email>
                <Phone>+1 (203) 991-6988</Phone> */}
              </>
            ),
          },
          {
            title: "For Business Enquiry",
            description: (
              <>
                <Address>
                  <AddressLine>
                    You can contact us through the following details:
                  </AddressLine>
                  {/* <AddressLine>Sacramento, CA 95820</AddressLine> */}
                </Address>
                <Email>
                  <a href="mailto:research@sigsail.com?cc=research@midas.center" style={{ color: "#6415FF" }}>
                    {" "}
                    research@sigsail.com
                  </a>
                </Email>
                <Phone>+91 8800629998</Phone>
              </>
            ),
          },
        ]}
      />
      {/* <Footer /> */}
    </AnimationRevealPage>
  );
};
