import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React, { useEffect, useState } from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { IconButton } from "@material-ui/core";
import sound from './sound/bensound-ukulele.mp3'

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are custom building it */
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

// import Hero from "components/hero/TwoColumnWithVideo.js";
// import Hero from "components/hero/TwoColumnWithInput.js";
// import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
// import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
// import Hero from "components/hero/FullWidthWithImage.js";
import Hero from "components/hero/BackgroundAsImage.js";
// import HeroProjects from "components/hero/BackgroundAsImageWithCenteredContent.js";

// import Collaboration from "components/features/ThreeColSimple.js";
// import Collaboration from "components/features/ThreeColWithSideImage.js";
// import SimpleFeatures from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import Features from "components/features/VerticalWithAlternateImageAndText.js";
import News from "components/features/VerticalWithAlternateImageAndTextNews";
// import SimpleFeatures from "components/features/DashedBorderSixFeatures";
// import MainFeature from "components/features/TwoColWithButton.js";
import Incubation from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";

// import Pricing from "components/pricing/ThreePlans.js";
// import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";

// import SliderCard from "components/cards/ThreeColSlider.js";
// import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
// import TabGrid from "components/cards/TabCardGrid.js";

import Collaboration from "components/blogs/ThreeColSimpleWithImage.js";
import Clients from "components/blogs/ThreeColSimpleWithImageClients";
import SimpleFeatures from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
// import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
// import Blog from "components/blogs/GridWithFeaturedPost.js";

// import Testimonial from "components/testimonials/TwoColumnWithImage.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// import Testimonial from "components/testimonials/SimplePrimaryBackground.js";

// import FAQ from "components/faqs/SimpleWithSideImage.js";
// import FAQ from "components/faqs/SingleCol.js";
// import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";

// import ContactUsForm from "components/forms/SimpleContactUs.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
// import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
//
// import GetStarted from "components/cta/GetStarted.js";
// import GetStarted from "components/cta/GetStartedLight.js";
// import DownloadApp from "components/cta/DownloadApp.js";

// import Footer from "components/footers/SimpleFiveColumn.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
// import Footer from "components/footers/FiveColumnWithBackground.js";
// import Footer from "components/footers/FiveColumnDark.js";
import Footer from "components/footers/MiniCenteredFooter.js";

/* Ready Made Pages (from demos folder) */
// import EventLandingPage from "demos/EventLandingPage.js";
// import HotelTravelLandingPage from "demos/HotelTravelLandingPage.js";
// import AgencyLandingPage from "demos/AgencyLandingPage.js";
// import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
// import RestaurantLandingPage from "demos/RestaurantLandingPage.js";
// import ServiceLandingPage from "demos/ServiceLandingPage.js";
// import HostingCloudLandingPage from "demos/HostingCloudLandingPage.js";

/* Inner Pages */
// import LoginPage from "pages/Login.js";
// import SignupPage from "pages/Signup.js";
// import PricingPage from "pages/Pricing.js";
// import AboutUsPage from "pages/AboutUs.js";
import ContactUsPage from "pages/ContactUs.js";
// import BlogIndexPage from "pages/BlogIndex.js";
// import TermsOfServicePage from "pages/TermsOfService.js";
// import PrivacyPolicyPage from "pages/PrivacyPolicy.js";

// import ComponentRenderer from "ComponentRenderer.js";
// import MainLandingPage from "MainLandingPage.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Suspense } from "react";
// import Project from "components/project/Project";
const Project = React.lazy(() => import('components/project/Project'));

export default function App(props) {
  const [playing, setPlaying] = useState(true);
  const [audio] = useState(
    new Audio(
      sound
    )
  );
  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);
  useEffect(() => {
    audio.loop = true;
    // audio.play();
    console.log("loaded");
    console.log(audio.paused);
  }, []);
  const pauseToggle = () => {
    console.log(audio.paused);
    setPlaying(!playing);
    console.log("jg");
    if (audio.paused) {
      audio.play();
    } else {
      audio.pause();
    }
  };
  // return <AnimationRevealPage disabled></AnimationRevealPage>;
  return (
    <React.Fragment>
      <IconButton
              style={{
                position: "absolute",
                right: "2rem",
                top: "6rem",
                color: "black",
                border: "none",
                outline: "none",
                zIndex: 500
              }}
              onClick={toggle}
            >
              {playing ? (
                <PauseCircleOutlineIcon fontSize="large" />
              ) : (
                <PlayCircleOutlineIcon fontSize="large" />
              )}
              {console.log(audio.paused)}
            </IconButton>
    <Router>
      <Switch>
        <Route path="/projects" exact>
          {/* <ComponentRenderer /> */}
          {/* <HeroProjects type="projects" /> */}
          <AnimationRevealPage>
            <Features />
          </AnimationRevealPage>
        </Route>
        <Route path="/sigsail-tms">
          <AnimationRevealPage>
            <SimpleFeatures />
          </AnimationRevealPage>
        </Route>
        <Route path="/news">
          <AnimationRevealPage>
            <News />
          </AnimationRevealPage>
        </Route>
        <Route path="/careers">
          <AnimationRevealPage>
            <ContactUsForm />
          </AnimationRevealPage>
        </Route>
        <Route path="/contact-us">
          <ContactUsPage />
        </Route>
        <Route path="/collaborations">
          <AnimationRevealPage>
            <Collaboration />
          </AnimationRevealPage>
        </Route>
        <Route path="/iiitd-incubated">
          <AnimationRevealPage>
            <Incubation />
          </AnimationRevealPage>
        </Route>
        <Route path="/clients">
          <AnimationRevealPage>
            {/* <HeroProjects /> */}
            <Clients />
          </AnimationRevealPage>
        </Route>
        <Route path="/projects/:name">
          {/* <MainLandingPage /> */}
          <AnimationRevealPage>
          <Suspense fallback={<div>Loading...</div>}>
        <Project />
      </Suspense>
          </AnimationRevealPage>
        </Route>
        <Route path="/">
          {/* <MainLandingPage /> */}
          <AnimationRevealPage>
            <Hero />
            <MainFeature />
            <Portfolio />
          </AnimationRevealPage>
        </Route>
      </Switch>
      <Footer />
    </Router>
    </React.Fragment>
  );
}

// export default EventLandingPage;
// export default HotelTravelLandingPage;
// export default AgencyLandingPage;
// export default SaaSProductLandingPage;
// export default RestaurantLandingPage;
// export default ServiceLandingPage;
// export default HostingCloudLandingPage;

// export default LoginPage;
// export default SignupPage;
// export default PricingPage;
// export default AboutUsPage;
// export default ContactUsPage;
// export default BlogIndexPage;
// export default TermsOfServicePage;
// export default PrivacyPolicyPage;

// export default MainLandingPage;
