import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import Header from "components/headers/light.js";
import { Box, Button } from "@material-ui/core";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
// const Textarea = styled(Input).attrs({ as: "textarea" })`
//   ${tw`h-24`}
// `;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;
const Submitdiv = tw.div`inline-block mt-8`;

export default ({
  subheading = "Careers",
  heading = (
    <>
      Have a <span tw="text-primary-500">Career</span>
      <wbr /> with us.
    </>
  ),
  description = "Are you interested in working with us? Drop your resume below and we will reach out to you.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    job: "",
    file: null,
  });
  const [isSent, setIsSent] = useState(false);
  // useEffect(() => {
  //   window.scrollTo(0,0)
  
  // }, [])

  const handleChange = (event) => {
    setIsSent(false);
    const { target } = event;
    let { value } = target;
    const { name } = target;
    console.log(target.files);
    console.log(name);
    if(target.files){
      const image = target.files[0];
      value= image
    }
    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const onSubmit = () => {};

  return (
    <Container>
      <Header />
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form action={formAction} method={formMethod}>
              <Input
                type="email"
                name="email"
                placeholder="Your Email Address"
                onChange={handleChange}
                value={userData.email}
              />
              <Input
                type="text"
                name="name"
                placeholder="Full Name"
                onChange={handleChange}
                value={userData.name}
              />
              <Input
                type="text"
                name="job"
                placeholder="Job Role"
                onChange={handleChange}
                value={userData.job}
              />
              <Box display="flex">
                <Button 
                  variant="contained"
                  component="label"
                  style={{ width: "50%", backgroundColor: "#4B10C0", color: "white", marginTop: "2rem" }}
                >
                  Upload CV
                  <input type="file" hidden name="file" onChange={handleChange} />
                </Button>
                <Submitdiv
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                 {userData.file? "File uploaded": ""}
                </Submitdiv>
              </Box>
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
