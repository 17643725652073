import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
// import Insta from "../../images/newImg/insta.png";
// import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
// import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import Header, {
  NavLink,
  // NavLinks,
  // PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";
// import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import { withRouter } from "react-router";
// import { IconButton } from "@material-ui/core";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none `}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url("https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-6  mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

// const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
//   padding-bottom: 56.25% !important;
//   padding-top: 0px !important;
//   ${tw`rounded`}
//   iframe {
//     ${tw`rounded bg-black shadow-xl`}
//   }
// `;

const Hero = (props) => {
  // const [playing, setPlaying] = useState(true);
  // const [audio] = useState(
  //   new Audio(
  //     "https://www.mastercard.co.in/content/dam/public/mastercardcom/in/en/audio/MastercardSoundscape.mp3"
  //   )
  // );
  // const toggle = () => setPlaying(!playing);
  // useEffect(() => {
  //   playing ? audio.play() : audio.pause();
  // }, [playing]);
  // useEffect(() => {
  //   // audio.load();
  //   // audio.autoplay = true;
  //   // audio.muted=true
  //   audio.loop = true;
  //   audio.play();
  //   console.log(audio.paused);
  // }, []);
  // const pauseToggle = () => {
  //   console.log(audio.paused);
  //   setPlaying(!playing);
  //   console.log("jg");
  //   if (audio.paused) {
  //     audio.play();
  //   } else {
  //     audio.pause();
  //   }
  // };
  // useEffect(() => {
  //   window.scrollTo(0,0)
  
  // }, [])
  // const navLinks = [
  //   <NavLinks key={1}>
  //     <NavLink href="/">Home</NavLink>
  //     <NavLink href="/projects">Projects</NavLink>
  //     <NavLink href="/sigsail-tms">SigSAIL TMS</NavLink>
  //     <NavLink href="/iiitd-incubated">IIITD-IC Incubated</NavLink>
  //     <NavLink href="/clients">Clients</NavLink>
  //     <NavLink href="/collaborations">Collaborations</NavLink>
  //     <NavLink href="/contact-us">Contact us</NavLink>
  //     <NavLink href="/news">News</NavLink>
  //     <NavLink href="/careers">Careers</NavLink>
  //   </NavLinks>,
  //   <NavLinks key={2}>
  //     {/* <PrimaryLink href="/#">
  //       Hire Us
  //     </PrimaryLink> */}
  //   </NavLinks>,
  // ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader />
        <TwoColumn>
          <LeftColumn>
            {/* <IconButton
              style={{
                position: "absolute",
                right: "2rem",
                top: "6rem",
                color: "white",
                border: "none",
                outline: "none",
              }}
              onClick={toggle}
            >
              {playing ? (
                <PauseCircleOutlineIcon fontSize="large" />
              ) : (
                <PlayCircleOutlineIcon fontSize="large" />
              )}
              {console.log(audio.paused)}
            </IconButton> */}
            {/* <img src={Insta}  /> */}
            <Notification>
              We are serving industry and government through our AI expertise.
            </Notification>
            <Heading>
              <span>SIGNAL SCIENCES AND ARTIFICIAL INTELLIGENCE </span>
              <br />
              <SlantedBackground>(SigSAIL) RESEARCH</SlantedBackground>
            </Heading>
            <PrimaryAction onClick={() => props.history.push("/projects")}>
              Learn More!
            </PrimaryAction>
          </LeftColumn>
          <RightColumn>
            {/* <StyledResponsiveVideoEmbed
              url="//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1"
              background="transparent"
            /> */}
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
export default withRouter(Hero);
