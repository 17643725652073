import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import lipreading from "../../images/newImg/talking (1).jpg";
import essay from "../../images/newImg/essay.jpg";
import coorection from "../../images/newImg/correction.jpg";
import alexa from "../../images/newImg/alexa.jpg";
import typing from "../../images/newImg/typing.jpg";
// import crams from "../../images/newImg/crams.jpeg";
import uni from "../../images/newImg/uni.jpg";
import test from "../../images/newImg/test.jpg";
// import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import 
// HeaderBase,
 {
  NavLinks,
  NavLink,
  // PrimaryLink,
} from "components/headers/light.js";
import Header from "components/headers/light.js";

const Container = tw.div`relative mx-0 mt-2`;
// const Header = tw(HeaderBase)`max-w-none`;
const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
// const StyledHeader = styled(Header)`
//   ${tw`pt-8 max-w-none w-full`}
//   ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
//     ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
//   }
//   ${NavToggle}.closed {
//     ${tw`text-gray-100 hover:text-primary-500`}
//   }
// `;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
// const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: essay,
      subtitle: "Paid",
      title: "Automatic Essay and Speech Scoring Systems",
      description:
        "Automated essay scoring (AES) is the use of specialized computer programs to assign grades to essays written in an educational setting. It is a form of educational assessment and an application of natural language processing. Its objective is to classify a large set of textual entities into a small number of discrete categories, corresponding to the possible grades, for example, the numbers 1 to 6.",
      // url: "/projects/Automatic Essay and Speech Scoring Systems",
    },
   
 
    {
      imageSrc: test,
      subtitle: "Paid",
      title: "SigSAIL TMS",
      description:
        "TMS custom test builder gives you endless ways evaluate professionals, translators, and interpreters using speaking, listening, reading and writing activities. Administer your certification exam to just a few test takers or hundreds of individuals -- uniformly and across numerous locations.",
      url: "/sigsail-tms",
    },
    {
      imageSrc: lipreading,
      subtitle: "Exclusive",
      title: "Visual Speech Recognition Systems",
      description:
        "Speechreading or lipreading is the technique of understanding and getting phonetic features from a speaker’s visual features such as movement of lips, face, teeth and tongue. It has a wide range of multimedia applications such as in surveillance, Internet telephony, and as an aid to a person with hearing impairments.",
      url: "/projects/Visual Speech Recognition Systems",
    },
    {
      imageSrc: alexa,
      subtitle: "Exclusive",
      title: "Automatic Speech Recognition Systems",
      description:
        'Automatic Speech Recognition (ASR) is the process of deriving the transcription (word sequence) of an utterance, given the audio. Speech recognition applications include voice user interfaces such as voice dialing (e.g. "call home"), call routing (e.g. "I would like to make a collect call"), domotic appliance control, search key words (e.g. find a podcast where particular words were spoken), simple data entry (e.g., entering a credit card number), preparation of structured documents (e.g. a radiology report), determining speaker characteristics,[2] speech-to-text processing (e.g., word processors or emails), and aircraft (usually termed direct voice input).',
      // url: "/projects/Automatic Speech Recognition Systems",
    },
    {
      imageSrc: typing,
      subtitle: "Exclusive",
      title: "Touchless Typing",
      description:
        "A novel touchless typing interface that makes use of an on-screen QWERTY keyboard and a smartphone camera. The keyboard was divided into nine color-coded clusters. The user moved their head toward clusters, which contained the letters that they wanted to type. A front-facing smartphone camera recorded the head movements. A bidirectional GRU based model which used pre-trained embedding rich in head pose features was employed to translate the recordings into cluster sequences. The model achieved an accuracy of 96.78% and 86.81% under intra- and inter-user scenarios, respectively, over a dataset of 2234 video sequences collected from 22 users.",
      url: "/projects/Touchless Typing",
    },
    {
      imageSrc: uni,
      subtitle: "Exclusive",
      title: "Course Recommendation And Management System",
      description:
        "CRAMS, an all in one tool for students to view, register, and get personalised recommendations for courses at their university. With personalised analytics students can even track their credits ,degree requirements along with getting an automated personalised timetable that checks for all the classes and tutorials clashes for you.",
      url: "/projects/Course Recommendation And Management System",
    },
    {
      imageSrc: coorection,
      subtitle: "Free",
      title: "Grammar Error Identification and Correction",
      description:
        "",
      // url: "/projects/Grammar Error Identification and Correction",
    },
  ];
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Home</NavLink>
      <NavLink href="/projects">Projects</NavLink>
      <NavLink href="/sigsail-tms">SigSAIL TMS</NavLink>
      <NavLink href="/sigsail-tms">IIITD-IC Incubated</NavLink>
      <NavLink href="#">Clients</NavLink>
      <NavLink href="#">Collaborations</NavLink>
      <NavLink href="#">Contact us</NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      {/* <PrimaryLink href="/#">
        Hire Us
      </PrimaryLink> */}
    </NavLinks>,
  ];

  return (
    <Container>
      <Header />
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Our Projects</HeadingTitle>
          <HeadingDescription>
            Here are some of the projects created by us that might help you in
            your busniess.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                {/* <Subtitle>{card.subtitle}</Subtitle> */}
                <Title> <a href={card.url}>{card.title}</a></Title>
                <Description>{card.description}</Description>
                {card.url? <Link href={card.url}>Learn More</Link>: null}
                
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
