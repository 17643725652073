import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import Header from "components/headers/light.js";
import MainImg from "../../images/newImg/iitd-incubated.jpg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(
  Column
)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-8 lg:mr-16 md:order-first`
    : tw`md:ml-8 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(
  SectionHeading
)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`;

// const Statistics = tw.div`mt-6 lg:mt-8 xl:mt-16 flex flex-wrap`;
// const Statistic = tw.div`text-lg sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`;
// const Value = tw.div`font-bold text-primary-500`;
// const Key = tw.div`font-medium text-gray-700`;

export default ({ textOnLeft = false }) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  // const statistics = [
  //   {
  //     key: "Countries",
  //     value: "192",
  //   },
  //   {
  //     key: "Hotels",
  //     value: "479",
  //   },
  //   {
  //     key: "Rooms",
  //     value: "2093",
  //   },
  //   {
  //     key: "Workers",
  //     value: "10347",
  //   },
  // ];

  return (
    <Container>
      <Header />
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={MainImg} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>IIITD-IC Incubated</Heading>
            <Description>
              Signal Sciences and Artificial Intelligence (SigSAIL) Pvt Ltd is a
              startup incubated at IIITD Innovation and Incubation Center
              (IIITDIC), Delhi{" "}
              <a href="https://iiitdic.in/" style={{ color: "#6415FF" }}>
                (https://iiitdic.in/)
              </a>{" "}
              on November 10, 2020. It is headed by Dr. Rajiv Ratn Shah{" "}
              <a
                href="https://iiitd.ac.in/rajivratn"
                style={{ color: "#6415FF" }}
              >
                (https://iiitd.ac.in/rajivratn)
              </a>
              , Assistant Professor in the Departments of Computer Science &
              Engineering and Human Centered Design at the Indraprastha
              Institute of Information Technology, Delhi (IIITD), which is a
              state university located in Delhi, India. More details of IIITDIC
              startups can be found at{" "}
              <a
                href="https://iiitdic.in/our-startups/"
                style={{ color: "#6415FF" }}
              >
                (https://iiitdic.in/our-startups/)
              </a>
              .
              <br />
              Quality AI development is expensive and requires significant
              hands-on experience. Building an AI team for your projects is
              unfeasible and it is difficult to get well-qualified AI
              researchers working in your area. With this motivation,
              researchers from IIITD have come together to form SigSAIL. We
              assist companies of all scales in building their AI-enabled
              products and services. SigSAIL provides the services of an expert
              team at a fraction of the original cost and hassle. We have
              experts in a variety of areas working on a host of industry
              problems. For instance, we have provided AI-enabled EduTech
              solutions to help clients in automating test evaluation and
              provide feedback to their test-takers. These systems now are
              serving &gt;10k test-taker per month worldwide, thus saving our
              clients significant cost and enhancing their customer experience
              and turn-around time.
            </Description>
            {/* <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
